<template>
    <div class="basic_data_edit">
        <div class="data_content scrollbar">
            <!-- 按钮 -->
            <div class="botton_box">
                <span class="menu_title">安全事故记录</span>
                <div class="button_list">
                    <sn-button
                        :snButton="snButton"
                        :class="{ showButton: !saveButton }"
                        @handleChange="handleChange"
                    ></sn-button>
                </div>
            </div>
            <!-- 表单 -->
            <el-form
                ref="formdata"
                :inline="true"
                :model="formdata.dataDic"
                :rules="rules"
                class="demo-form-inline form_row"
                size="small"
                label-width="200px"
            >
                <el-form-item
                    prop="FSSJ"
                    label="发生时间"
                    class="from-item-block"
                >
                    <el-date-picker
                        v-model="formdata.dataDic.FSSJ"
                        type="date"
                        placeholder="选择日期"
                        value-format="yyyy-MM-dd"
                        :disabled="!isEdit"
                    >
                    </el-date-picker>
                </el-form-item>
                <el-form-item
                    prop="SGLX"
                    label="事故类型"
                    class="from-item-block"
                >
                    <el-input
                        v-model="formdata.dataDic.SGLX"
                        :disabled="!isEdit"
                        :maxlength="50"
                    ></el-input>
                </el-form-item>
                <el-form-item prop="JB" label="级别" class="from-item-block">
                    <el-select
                        id="ycys"
                        v-model="formdata.dataDic.JB"
                        :disabled="!isEdit"
                    >
                        <el-option
                            v-for="(item, index) in SGLXList"
                            :key="`${index}`"
                            :value="item.CODE"
                            :label="item.LABEL"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item prop="SS" label="损失" class="from-item-block">
                    <el-input
                        v-model="formdata.dataDic.SS"
                        type="textarea"
                        :disabled="!isEdit"
                        :autosize="{ minRows: 2, maxRows: 6 }"
                        :maxlength="500"
                    ></el-input>
                </el-form-item>
                <el-form-item
                    prop="CLQK"
                    label="处理情况"
                    class="from-item-block"
                >
                    <el-input
                        v-model="formdata.dataDic.CLQK"
                        resize="none"
                        type="textarea"
                        :disabled="!isEdit"
                        :autosize="{ minRows: 2, maxRows: 8 }"
                        :maxlength="4000"
                    ></el-input>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
import { mapActions } from "vuex";
import infoMixin from "../0_com_js/info_mixin.js";
export default {
    name: "aqsgjl",
    mixins: [infoMixin],
    data() {
        return {
            isEdit: false,
            saveButton: true,
            formdata: {
                itemCode: "1303",
                dataDic: {
                    glycbtid: this.heritageId,
                    FSSJ: "",
                    SGLX: "",
                    JB: "",
                    SS: "",
                    CLQK: "",
                },
            },
            rules: {
                FSSJ: [
                    {
                        required: true,
                        message: "请选择发生时间",
                        trigger: "change",
                    },
                ],
                SS: [
                    { required: true, message: "请填写损失", trigger: "blur" },
                ],
                CLQK: [
                    {
                        required: true,
                        message: "请填写处理情况",
                        trigger: "blur",
                    },
                ],
            },
            SGLXList: [],
        };
    },
    mounted() {
        this.GetEnum();
    },
    methods: {
        ...mapActions(["getAllEnumInfobytableID"]),
        async GetEnum() {
            let res = await this.getAllEnumInfobytableID({
                bid: "1303",
            });
            let enumList = res || [];
            enumList.forEach((v) => {
                if (v.COLUMNID === "JB") {
                    this.SGLXList = v.dataItems;
                }
            });
        },
    },
};
</script>

<style>
.yearBox .yearItem {
    width: 48% !important;
}
.yearBox .separate {
    padding: 0 10px;
}
</style>
